import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import Pagination from "../components/pagination"

const projectListingTemplate = props => {
  const { pageContext, data } = props
  const { previousPagePath, nextPagePath, humanPageNumber, numberOfPages } =
    pageContext

  console.log("pageContext", pageContext)
  console.log("data", data)
  return (
    <Layout>
      <section className="news-post__header">
        <div className="news-post__header-overlay"></div>
        <GatsbyImage
          className="h-full"
          // image={post.featuredImage.asset.gatsbyImageData}
          // alt={post.featuredImage.asset.altText}
        />
        <div className="news-post__header-content">
          <h1 className="display">Our Projects</h1>
        </div>
      </section>

      <section className="py-16">
        <div className="site-container">
          <div className="news-listing__list grid grid-cols-1 lg:grid-cols-3 gap-8">
            {data.allSanityProjects.edges.map((post, index) => (
              <article>
                <Link to={"/projects/" + post.node.slug.current}>
                  <GatsbyImage
                    className="aspect-video"
                    image={post.node.featuredImage.asset.gatsbyImageData}
                    alt={post.node.featuredImage.asset.altText}
                  />
                  <h2 className="font-bold">{post.node.title}</h2>
                </Link>
              </article>
            ))}
          </div>

          <Pagination pageContext={pageContext} />
        </div>
      </section>
    </Layout>
  )
}

export default projectListingTemplate

export const query = graphql`
  query ($skip: Int!, $limit: Int!) {
    allSanityProjects(
      sort: { fields: _createdAt, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          _createdAt(formatString: "MMMM DD, YYYY")
          _id
          _key
          _type
          _updatedAt(formatString: "MMMM DD, YYYY")
          featuredImage {
            _type
            _key
            asset {
              altText
              gatsbyImageData
            }
          }
          id
          title
          slug {
            _key
            _type
            current
          }
        }
      }
    }
  }
`
